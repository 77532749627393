import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Banner } from '../components/banner';
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
import { StudentpageBannerContent } from '../components/studentpage-banner-content';
import { StudentpageContent } from '../components/studentpage-content';
// import student_page_banner from '../images/student-page-banner.png';

const StudentPage = () => (
    <Layout>
        <SEO title="Student Page" />
        <Banner
            imgURL='student-page-banner.png'
            imgHeight={536}
            content={<StudentpageBannerContent />}
        />
        <StudentpageContent />
        <TiggbeeContactUs
            titleElement={<div className='contact-us-quote'>"Success at anything will always come down to this: Focus and effort. And we control both." - <span className='contact-us-quote-author'>Dwayne Johnson</span></div>}
        />
    </Layout>
)

export default StudentPage