import React from 'react';
import './banner.css';
// import business_page_banner_gradient_background from '../images/business-page-banner-gradient-background.svg';

export class StudentpageBannerContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className='banner-content'>
                <div className='banner-content-background'
                    style={{
                        backgroundImage: `url(/banner-gradient-background-1.png)`
                    }}
                ></div>
                <div className='banner-title homepage-banner-title student-banner-title'>
                    Meet Individuals that are<br />Passionate about their Careers
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}